import PropTypes from 'prop-types';

export const fluidShape = PropTypes.shape({
  childImageSharp: PropTypes.shape({
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
    }),
  }),
});
