import React from 'react';
import { fluidShape } from 'utils/proptypes';
import Img from 'gatsby-image';
import {
  Wrapper,
  Title,
  Description,
  StyledSectionTitle,
  CollabWrapper,
  ImageWrapper,
} from './Collab.styles';

const Courses = ({ image }) => (
  <Wrapper>
    <div>
      <StyledSectionTitle>kim jest Roman?</StyledSectionTitle>
      <Description>
        Sam siebie nazywam kreatywnym frontendowcem, ponieważ zanim zacząłem
        zarabiać na pisaniu kodu pracowałem jako copywriter w wielu agencjach
        reklamowych. Doświadczenie, które zdobyłem jako kreatywny ułatwiło mi
        stworzenie vloga, na którym dzielę się moim doświadczeniem i wiedzą.
        Moim głównym celem jest pomoc innym w realizacji marzenia o zostaniu
        programistą oraz stworzenie społeczności, którą łączy pasja do tworzenia
        oprogramowania.
      </Description>
      <CollabWrapper>
        <Title>współpraca</Title>
        <Description>
          Masz pomysł na współpracę z moim kanałem? Napisz do mnie, a z chęcią
          podzielę się statystykami i formami promocji, które oferuję.
          <br />
          <br />
          Organizujesz ciekawy event wspierający branżę IT, prowadzisz vloga lub
          podcast? Pomyślmy razem, co ciekawego możemy stworzyć!
        </Description>
      </CollabWrapper>
    </div>
    <ImageWrapper>
      <Img fluid={image.childImageSharp.fluid} />
    </ImageWrapper>
  </Wrapper>
);

Courses.propTypes = {
  image: fluidShape.isRequired,
};

export default Courses;
