import styled from 'styled-components';
import Img from 'gatsby-image';
import Button from 'components/Button/Button';
import trianglesWhite from 'assets/images/pattern_triangles_white.svg';

export const Wrapper = styled.div`
  padding: 36px;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.primary};
  background-image: url(${trianglesWhite});
  background-repeat: no-repeat;
  background-position: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: flex-start;

  ${({ theme }) => theme.mq.desktop} {
    background-color: unset;
    background-image: linear-gradient(
      120deg,
      ${({ theme }) => theme.white},
      ${({ theme }) => theme.white} 60%,
      ${({ theme }) => theme.primary} 60%,
      ${({ theme }) => theme.primary}
    );
    padding: 100px;
  }

  ${({ theme }) => theme.mq.huge} {
    padding: 250px;
  }
`;

export const Header = styled.h1`
  font-family: ${({ theme }) => theme.font.family.neuville};
  font-size: ${({ theme }) => theme.font.size.l};
  line-height: 1.1;
  margin: 0;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.xxl};
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      background-color: ${({ theme }) => theme.primary};
      width: 250px;
      height: 140px;
      left: -30px;
      top: -30px;
      z-index: -1;
    }
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 15px;

  &::before {
    background-color: ${({ theme }) => theme.white};
  }

  ${({ theme }) => theme.mq.desktop} {
    &::before {
      background-color: ${({ theme }) => theme.primary};
    }
  }
`;

export const Image = styled(Img)`
  display: none;

  ${({ theme }) => theme.mq.desktop} {
    display: block;
    position: absolute !important;
    width: 50vw;
    right: 30px;
    bottom: 0;
  }
`;
