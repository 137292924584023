export const courses = [
  {
    title: 'React od podstaw',
    info: 'data premiery – 13.03.2019',
    description: `W tym kursie pokażę Ci, w jaki sposób możesz wykorzystać pełen potencjał Reacta, zanim poznasz jego cały ekosystem.`,
    link: 'https://eduweb.pl/programowanie-i-www/reactjs/react-od-podstaw?ref=helloroman',
  },
  {
    title: 'React w praktyce',
    info: 'data premiery – 10.05.2019',
    description: `Czas wejść na wyższy poziom i poznać potęgę ekosystemu React. Oprócz zaawansowanych rozwiązań, poznasz też sposoby konfiguracji projektu, oraz dowiesz się, w jaki sposób efektywnie wykorzystywać dostępne biblioteki.`,
    link: 'https://eduweb.pl/programowanie-i-www/reactjs/react-w-praktyce?ref=helloroman',
  },
  {
    title: 'React zaawansowany',
    info: 'data premiery – 10.09.2019',
    description: `Ostatni z moich kursów o React, gdzie poruszymy kwestie związane z najbardziej zaawansowanymi wzorcami. Nie zbudujemy tu pięknych interfejsów, ani całej aplikacji. Skupimy się na wielu realnych problemach, z którymi zmagają się developerzy na co dzień.`,
    link: 'https://eduweb.pl/programowanie-i-www/reactjs/react-techniki-zaawansowane?ref=helloroman',
  },
  {
    title: 'Gatsby od podstaw',
    info: 'data premiery – 13.02.2020',
    description: `Najnowszy kurs poświęcony świetnej bibliotece Gatsby.js – pozwalającej tworzyć niesamowicie szybkie strony. W kursie opowiem o sposobach na stworzenie bloga, użycie Headless CMS a także o tym, jak optymalizować zasoby wykorzystując Gatsbyego`,
    link: 'https://eduweb.pl/programowanie-i-www/reactjs/gatsby-generator-react?ref=helloroman',
  },
];
