import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import iconInstagram from 'assets/images/contact_instagram.svg';
import iconMsg from 'assets/images/contact_msg.svg';
import Button from 'components/Button/Button';
import wavesImage from 'assets/images/pattern_waves.svg';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ContactIcons, FormWrapper, Description, StyledSectionTitle, StyledIcon, StyledLink, Input, Wrapper, WavesImage, StyledMessage } from './Contact.styles';

const contactSchema = Yup.object().shape({
  email: Yup.string().email('wpisz poprawny adres').required('wpisz swój email'),
  message: Yup.string().required('napisz wiadomość'),
});

const Contact = () => {
  const [isSubmitted, setSubmissionState] = useState(false);
  const [isLoading, setLoadingState] = useState(false);
  const [hasSendingFailed, setSendingError] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = async (data) => {
    if (!executeRecaptcha) {
      return;
    }
    try {
      setLoadingState(true);
      await axios.post('https://asao4iwgul.execute-api.eu-west-1.amazonaws.com/default/helloroman_send_email_prod', {
        name: 'Roman',
        email: data.email,
        message: data.message,
      });
      setSubmissionState(true);
      setLoadingState(false);
    } catch (e) {
      setSendingError(true);
      setSubmissionState(false);
      setLoadingState(false);
    }
  };

  return (
    <Wrapper>
      <WavesImage src={wavesImage} />
      <StyledSectionTitle>kontakt</StyledSectionTitle>
      <Description>Możesz mnie znaleźć w paru miejscach w sieci, ale jeśli wolisz zrobić to „po staremu” to po prostu napisz do mnie wiadomość.</Description>
      <ContactIcons>
        <StyledLink rel="noopener norefferer" target="_blank" href="https://instagram.com/siemaroman">
          <StyledIcon src={iconInstagram} text="Instagram" />
        </StyledLink>
        <StyledLink rel="noopener norefferer" target="_blank" href="https://facebook.com/helloroman.vlog">
          <StyledIcon src={iconMsg} text="Messenger" />
        </StyledLink>
      </ContactIcons>
      <Formik initialValues={{ email: '', message: '' }} validationSchema={contactSchema} onSubmit={handleSubmit}>
        {({ values, touched, errors, handleChange, handleBlur, handleSubmit: handleFormikSubmit }) => (
          <FormWrapper onSubmit={handleFormikSubmit}>
            <Input onChange={handleChange} onBlur={handleBlur} value={values.email} name="email" type="email" placeholder="Twój email" autoComplete="off" />
            <Input onChange={handleChange} onBlur={handleBlur} value={values.message} name="message" as="textarea" placeholder="w czym mogę Ci pomóc?" />
            <Button type="submit" disabled={isLoading}>
              {isLoading ? '...' : 'Wyślij'}
            </Button>
            {(errors.email && touched.email) || (errors.message && touched.message) ? (
              <StyledMessage>
                Hej, zanim wyślesz: &nbsp;
                {errors.email && touched.email ? `${errors.email}` : ''}
                &nbsp;
                {errors.email && touched.email && errors.message && touched.message ? 'i ' : ''}
                {errors.message && touched.message ? `${errors.message}` : ''}
              </StyledMessage>
            ) : null}
            {hasSendingFailed ? <StyledMessage>Coś poszło nie tak, spróbuj pownownie</StyledMessage> : null}
            {isSubmitted ? (
              <StyledMessage>
                Poszło! Dzięki za wiadomość{' '}
                <span aria-label="heart" role="img">
                  ♥️
                </span>
              </StyledMessage>
            ) : null}
          </FormWrapper>
        )}
      </Formik>
    </Wrapper>
  );
};

export default Contact;
