import styled from 'styled-components';
import Button from 'components/Button/Button';
import { SectionTitle } from 'components/Typo';

export { Paragraph } from 'components/Typo';

export const StyledSectionTitle = styled(SectionTitle)`
  ${({ theme }) =>
    theme.addBackground('100%', '50%', '0', '-20px', theme.white)};

  ${({ theme }) => theme.mq.desktop} {
    grid-row: 1;
    grid-column: 1 / 3;
    ${({ theme }) => theme.addBackground('80%', '40%', '0', '-50px')};
  }
`;

export const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.primary};

  ${({ theme }) => theme.mq.desktop} {
    padding: 100px 10vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-template-rows: 1fr auto;
    background-color: ${({ theme }) => theme.grey};
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 15px;

  &::before {
    background-color: ${({ theme }) => theme.white};
  }

  ${({ theme }) => theme.mq.desktop} {
    &::before {
      background-color: ${({ theme }) => theme.primary};
    }
  }
`;

export const CourseWrapper = styled.div`
  margin: 20px 0 80px;
  align-self: start;

  ${({ theme }) => theme.mq.desktop} {
    margin: 40px 0;
    align-self: start;
    justify-self: start;

    p {
      max-width: 650px;
    }
  }
`;

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.font.family.neuville};
  font-size: ${({ theme }) => theme.font.size.s};
  margin: 0;

  ${({ theme }) => theme.mq.desktop} {
    position: relative;
    z-index: 1;

    &::before {
      z-index: -1;
      background-color: ${({ theme }) => theme.primary};
      clip-path: polygon(100% 0, 0% 100%, 100% 100%);
      width: 50px;
      height: 50px;
      left: -25px;
      content: '';
      position: absolute;
      top: -22px;
    }
  }
`;

export const Info = styled.p`
  font-family: ${({ theme }) => theme.font.family.neuville};
  font-weight: bold;
  margin: 0 0 20px;
`;
