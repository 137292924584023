import React from 'react';
import { courses } from 'data/courses';
import { Wrapper, Info, Title, StyledSectionTitle, Paragraph, StyledButton, CourseWrapper } from './Courses.styles';

const Courses = () => (
  <Wrapper>
    <StyledSectionTitle>kursy</StyledSectionTitle>
    {courses.map(({ title, info, description, link = null }) => (
      <CourseWrapper key={info}>
        <Title>{title}</Title>
        <Info>{info}</Info>
        <Paragraph>{description}</Paragraph>
        {link && (
          <StyledButton as="a" rel="noopener noreferrer" target="_blank" href={link} small>
            Sprawdź kurs
          </StyledButton>
        )}
      </CourseWrapper>
    ))}
  </Wrapper>
);

export default Courses;
