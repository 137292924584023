import styled from 'styled-components';

import { SectionTitle } from 'components/Typo';

export const StyledSectionTitle = styled(SectionTitle)`
  ${({ theme }) => theme.addBackground('60%', '50%', '0', '-20px')};

  ${({ theme }) => theme.mq.desktop} {
    ${({ theme }) => theme.addBackground('80%', '70%', '0', '-50px')};
  }
`;

export const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  ${({ theme }) => theme.mq.desktop} {
    padding: 100px 10vw;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.font.family.neuville};
  font-size: ${({ theme }) => theme.font.size.s};
  margin: 0;

  ${({ theme }) =>
    theme.addBackground('30%', '40%', '-5%', '-20px', theme.white)};

  ${({ theme }) => theme.mq.desktop} {
    grid-column: 1;

    ${({ theme }) =>
      theme.addBackground('20%', '20%', '15%', '20px', theme.white)};
  }
`;

export const Description = styled.p`
  margin: 25px 0;
  font-family: ${({ theme }) => theme.font.family.raleway};
  font-weight: 400;
  line-height: 1.55;
  font-size: ${({ theme }) => theme.font.size.xs};

  ${({ theme }) => theme.mq.desktop} {
    max-width: 500px;
    grid-column: 1;
    align-self: start;
  }
`;

export const CollabWrapper = styled.div`
  margin: 70px 0 20px;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    height: 160%;
    width: 100vw;
    top: -30%;
    left: -30px;
    position: absolute;
    background-color: ${({ theme }) => theme.primary};
    z-index: -1;
  }

  ${({ theme }) => theme.mq.desktop} {
    margin: 0;
    align-self: start;
    grid-column: 1;
    background-color: ${({ theme }) => theme.primary};
    padding: 50px;

    &::before {
      display: none;
    }
  }
`;

export const Link = styled.a`
  color: black;
  font-family: ${({ theme }) => theme.font.family.neuville};
  font-weight: bold;

  ${({ theme }) => theme.mq.desktop} {
    grid-column: 1;
  }
`;

export const ImageWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mq.desktop} {
    flex-shrink: 0;
    margin-left: 50px;
    width: 40vw;
    height: auto;
    display: block;
  }
`;
