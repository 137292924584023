import React from 'react';
import { fluidShape } from 'utils/proptypes';
import { StyledButton, Header, Image, Wrapper } from './Hero.styles';

const Hero = ({ image }) => (
  <Wrapper>
    <Header>
      Cześć,
      <br />
      tu Roman!
    </Header>
    <StyledButton
      as="a"
      href="https://youtube.com/helloroman"
      target="_blank"
      rel="noreferrer noopener"
    >
      sprawdź mój vlog
    </StyledButton>
    <Image fluid={image.childImageSharp.fluid} />
  </Wrapper>
);

Hero.propTypes = {
  image: fluidShape.isRequired,
};

export default Hero;
