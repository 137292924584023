import React from 'react';
import styled from 'styled-components';
import { graphql, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import MainTemplate from 'templates/MainTemplate/MainTemplate';
import Hero from 'templates/Hero/Hero';
import Courses from 'templates/Courses/Courses';
import Collab from 'templates/Collab/Collab';
import Contact from 'templates/Contact/Contact';
import { fluidShape } from 'utils/proptypes';
import Button from 'components/Button/Button';

const MailingButton = styled(Button)`
  display: none;

  ${({ theme }) => theme.mq.desktop} {
    height: 60px;
    display: block;
    position: fixed;
    bottom: 15px;
    right: 10px;
    z-index: 9999;

    &::after {
      border: 2px solid black;
    }
  }
`;

const IndexPage = ({ data: { collab, hero } }) => {
  return (
    <MainTemplate>
      <MailingButton onClick={() => navigate('/newsletter')}>
        Zapisz się do newslettera!
      </MailingButton>
      <Hero image={hero} />
      <Courses />
      <Collab image={collab} />
      <Contact />
    </MainTemplate>
  );
};

export const query = graphql`
  query {
    hero: file(name: { regex: "/photo_hero/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    collab: file(name: { regex: "/photo_collab/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.shape({
    hero: fluidShape.isRequired,
    collab: fluidShape.isRequired,
  }).isRequired,
};

export default IndexPage;
