import styled from 'styled-components';
import Icon from 'components/Icon/Icon';
import { SectionTitle, Paragraph } from 'components/Typo';

export { Input } from 'components/Input/Input';

export const WavesImage = styled.img`
  display: none;
  object-fit: contain;
  position: absolute;
  right: 5%;
  top: 15%;
  z-index: -1;

  ${({ theme }) => theme.mq.desktop} {
    display: block;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.mq.desktop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 10vw 100px 10vw;
  }
`;

export const StyledSectionTitle = styled(SectionTitle)`
  ${({ theme }) => theme.addBackground('100%', '50%', '0', '-20px')};

  ${({ theme }) => theme.mq.desktop} {
    ${({ theme }) => theme.addBackground('100%', '70%', '0', '-50px')};
    align-self: end;
    margin-bottom: 0;
  }
`;

export const Description = styled.p`
  margin: 25px 0;
  font-family: ${({ theme }) => theme.font.family.raleway};
  font-weight: 400;
  line-height: 1.5;
  font-size: ${({ theme }) => theme.font.size.xs};

  ${({ theme }) => theme.mq.desktop} {
    grid-column: 1;
    align-self: start;
    width: 70%;
  }
`;

export const ContactIcons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  align-self: start;

  svg {
    margin: 0 20px;
  }

  ${({ theme }) => theme.mq.desktop} {
    grid-column: 1;
    flex-direction: column;

    svg {
      margin: 20px 0;
    }
  }
`;

export const FormWrapper = styled.form`
  position: relative;
  margin: 50px 0;
  width: 100%;
  background: ${({ theme }) => theme.grey};
  padding: 15px 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  input,
  textarea {
    width: 100%;
    margin: 0 0 20px;
  }

  textarea {
    min-height: 200px;
    resize: none;
  }

  button {
    margin: 25px 0 0;
  }

  ${({ theme }) => theme.mq.desktop} {
    padding: 30px;
    grid-column: 2;
    grid-row: 1 / span 3;
    margin-top: 100px;
    position: relative;
    top: 50px;
  }
`;

export const StyledLink = styled.a`
  color: black;
  display: inline-block;
  width: auto;
`;

export const StyledIcon = styled(Icon)`
  ${({ theme }) => theme.mq.desktop} {
    position: relative;

    &::after {
      content: '${({ text }) => text}';
      position: absolute;
      font-family: ${({ theme }) => theme.font.family.neuville};
      font-weight: bold;
      top: 50%;
      transform: translateY(-50%);
      left: 50px;
    }
  }
`;

export const StyledMessage = styled(Paragraph)`
  position: absolute;
  z-index: 9999;
  bottom: 80px;
  margin: 0;
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.size.xxs};

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.xs};
  }
`;
